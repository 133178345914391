import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { RootState } from '../../store';
import TagCard from './TagCard';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Grid, Snackbar } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      fontSize: '14px',
      fontWeight: 'normal',
      width: '70%',
    },
  }),
);

function Tag() {
  const classes = useStyles();

  useFirestoreConnect([{ collection: 'fbMapping', where: ['status', '==', 'pending'] }]);
  const firestore = useFirestore();
  const tasks = useSelector((state: RootState) => state.firestore.data.fbMapping);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const saveName = async (id: string, name: string) => {
    if (name.length === 0) setAlertOpen(true);
    else await firestore.doc(`fbMapping/${id}`).update({ status: 'mapped', userName: name });
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <React.Fragment>
      {tasks && Object.keys(tasks).length > 0 ? (
        <Grid container spacing={2} justify="center">
          <Alert severity="info" variant="filled" className={classes.text}>
            {`${
              Object.keys(tasks)
                .map(key => {
                  if (tasks[key]) return key;
                })
                .filter(item => item).length
            } Remaining Mapping Tasks`}
          </Alert>

          {Object.keys(tasks).map(key => {
            return (
              <Grid item xs={12} container justify="center" key={key}>
                {tasks[key] && <TagCard msg={tasks[key].msg} type={tasks[key].type} id={key} saveName={saveName} />}
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container justify="center">
          <Alert severity="success" variant="filled" className={classes.text}>
            All Facebook name tagging tasks are completed for now.
          </Alert>
        </Grid>
      )}

      <Snackbar open={alertOpen} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" variant="filled" className={classes.text}>
          Facebook name field cannot be empty!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Tag;
