import * as React from 'react';
import { TagCardProps } from './types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActions, Button, Typography, TextField } from '@material-ui/core';
const BASE_URL = 'https://www.facebook.com/groups';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '70%',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2, 2),
    },
    message: {
      fontStyle: 'italic',
      marginBottom: theme.spacing(2),
    },
    text: {
      fontSize: '14px',
    },
  }),
);

function TagCard(props: TagCardProps) {
  const classes = useStyles();
  const [fbName, setFbName] = React.useState('');

  // Get Facebook link for a specific post or comment
  const getFacebookLink = () => {
    if (props.type === 'post') {
      const idArr = props.id.split('_');
      return `${BASE_URL}/${idArr[0]}/posts/${idArr[1]}`;
    } else {
      const tmp = props.id.split('-');
      const idArr = tmp[0].split('_');
      return `${BASE_URL}/${idArr[0]}/posts/${idArr[1]}/comments/${tmp[1]}`;
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2" color="textSecondary" className={classes.message}>
          {`“${props.msg}”`}
        </Typography>

        <TextField
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFbName(event.target.value);
          }}
          margin="dense"
          id="fbName"
          fullWidth
          label="Facebook Name"
          value={fbName}
          InputLabelProps={{
            classes: {
              root: classes.text,
            },
          }}
          InputProps={{
            classes: {
              input: classes.text,
            },
          }}
        />
      </CardContent>

      <CardActions>
        <Button size="small" color="primary" className={classes.text} onClick={() => window.open(getFacebookLink())}>
          View on Facebook
        </Button>
        <Button
          size="small"
          color="primary"
          className={classes.text}
          onClick={() => {
            props.saveName(props.id, fbName);
            setFbName('');
          }}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}

export default TagCard;
